/*
 * Setup imports and global vars
 */
import {} from './sentry';
import {} from 'arrive'; // https://github.com/uzairfarooq/arrive

import renderBreadcrumbs from './breadcrumbs';
let bDebug = false;

const supportsContainerQueries = 'container' in document.documentElement.style;
if (!supportsContainerQueries) {
	require('container-query-polyfill');
}

const startRenderApiDependents = () => {
	/***********************************************************
	 * Slot manipulations
	 ***********************************************************/
	//when in site builder edit mode, show all slot content
	if (SCSRenderAPI.renderMode === 'edit') {
		document.querySelectorAll('.scs-slot').forEach((el) => el.classList.add('showing'));
	}
	if (typeof document.querySelector('body > header')?.textContent == 'undefined') {
		document.querySelectorAll('.scs-slot').forEach((el) => el.classList.add('showing'));
	}

	// Remove extra margin around slots with no content
	document.arrive('#slot-hero, #slot-hero > *', { existing: true }, (el) => {
		const heroSlot = document.querySelector('#slot-hero');

		// take no action on the homepage
		if (heroSlot?.closest('body')?.classList.contains('layout-homepage')) return;

		// check if there is content in the slot
		if (heroSlot?.children?.length) {
			heroSlot.arrive(
				'[id$=customComponentDiv]',
				{ existing: true, onceOnly: true },
				(element) => {
					// console.log('custom component div found, unhiding', element)
					document.querySelector('#slot-hero')?.classList.add('no-content');
					element.arrive('*', { existing: true }, (element) => {
						document.querySelector('#slot-hero')?.classList.remove('no-content');
					});
				}
			);
			// console.log('content', heroSlot.children.length == true, el)
		} else {
			// console.log('no content', heroSlot.children.length == true, el)
			document.querySelector('#slot-hero')?.classList.add('no-content');
		}
	});
	document.arrive(
		'#slot-content-image [id$=customComponentDiv]',
		{ existing: true, onceOnly: true },
		(element) => {
			document.querySelector('#slot-content-image')?.classList.add('no-content');
			element.arrive('*', { existing: true }, (element) => {
				document.querySelector('#slot-content-image')?.classList.remove('no-content');
			});
		}
	);

	/***********************************************************
	 * Page manipulations
	 ***********************************************************/
	// Set the page title. Prefers the title in the page properties, but fallbacks to name (which is always populated)
	document.arrive('title', { existing: true }, (elTitle) => {
		let strTitle = SCSRenderAPI.pageModel.properties.title
			? SCSRenderAPI.pageModel.properties.title
			: SCS.structureMap[SCS.navigationCurr].name;
		elTitle.innerText = strTitle;
	});
	// When a link with a hash is clicked, the code finds the first details element that is not open, and opens it.
	// If there is a parent details element that is not open, it also opens that.
	// This continues until there are no more parent details elements that are not open.
	// Then, it scrolls the element into view.
	document.arrive('a', { existing: true, fireOnAttributesModification: true }, (anchor) => {
		anchor.addEventListener('click', (e) => {
			let hash = anchor.href.split('#')[1];
			let hashElement = document.getElementById(hash);
			let originalDetails = hashElement?.closest('details');
			if (hash && hashElement && originalDetails) {
				e.preventDefault();
				let target = document.getElementById(hash);
				window.location.hash = hash;
				let currentDetails = null;
				let details = target.closest('details');
				while (details) {
					currentDetails = details;
					if (details) {
						details.setAttribute('open', '');
						details = details.parentElement.closest('details');
						if (details) {
							currentDetails = details;
						}
					} else {
						details = null;
					}
				}

				if (currentDetails) {
					currentDetails.setAttribute('open', '');
					currentDetails.classList.add('open');
					originalDetails.scrollIntoView({ behavior: 'smooth', block: 'center' });
				}
			}
		});
	});

	// This code handles accordions.
	// If the page loads with a hash in the url, this code will open the accordion to that section of the page.
	// It does this by using the arrive.js library to detect when a details element is added to the DOM and-
	// -then checks if it is the details element that is being referenced in the url hash and if so,-
	// -it opens that details element and all of its parent details elements.

	// render the breadcrumbs
	renderBreadcrumbs();
	/***********************************************************
	 * Re-write links to allow opening them in a defined way
	 ***********************************************************/
	// Utility function for setting the download flags on links
	const setDownloadFlags = (element) => {
		if (SCSRenderAPI.getRenderMode() != SCSRenderAPI.RENDER_MODE_EDIT) {
			const href = element.getAttribute('href');
			if (bDebug) console.log({ href });
			if (href.includes('published/api/') || href.includes('management/api/')) {
				const url = new URL(href, window.location.origin);

				url.searchParams.set('download', 'false');
				element.removeAttribute('download');
				if (bDebug)
					console.log({
						href: element.getAttribute('href'),
						new_href: `${href.split('?')[0]}?${url.searchParams.toString()}`,
					});
				element.setAttribute('href', url.toString());
				element.setAttribute('target', '_blank');
				element.setAttribute('rel', 'noopener noreferrer');
			}
		}
	};

	//Replace download flags for links, only when not in edit mode
	document.arrive('.scs-paragraph-text a', { existing: true }, (element) => {
		setDownloadFlags(element);
	});
	document.arrive('.scs-button-button', { existing: true }, (element) => {
		setDownloadFlags(element);
	});

	document.arrive('.scs-contentlist a', { existing: true }, (element) => {
		setDownloadFlags(element);
	});

	document.arrive('.navList a', { existing: true }, (element) => {
		setDownloadFlags(element);
	});

	/***********************************************************
	 * Print Settings
	 ***********************************************************/
	const detailsBeforePrint = () => {
		// open closed details elements for printing
		const allDetails = document.body.querySelectorAll('details');
		for (const element of allDetails) {
			if (element.open) {
				element.dataset.open = '1';
			} else {
				element.setAttribute('open', '');
			}
		}
	};

	const detailsAfterPrint = () => {
		// after printing close details elements not opened before
		const allDetails = document.body.querySelectorAll('details');
		for (const element of allDetails) {
			if (element.dataset.open) {
				element.dataset.open = '';
			} else {
				element.removeAttribute('open');
			}
		}
	};

	window.addEventListener('beforeprint', () => {
		detailsBeforePrint();
	});

	window.addEventListener('afterprint', () => {
		detailsAfterPrint();
	});
};

/**
 * Handles URL hash changes and scrolls to the corresponding element.
 */
const handleURLHash = () => {
	/**
	 * Callback function for the 'arrive' event.
	 * @param {HTMLElement} elem - The element that matches the selector.
	 */
	const handleArriveEvent = (elem) => {
		// Check if the element has the 'showing' class
		if (!elem.classList.contains('showing')) return;

		// Get the current URL and hash value
		const url = new URL(window.location.href);
		let hash = url.hash;

		// If no hash value exists, return
		if (!hash) return;

		// Wait for the element with the hash value to exist in the document
		document.arrive(hash, { existing: true }, (el) => {
			let currentDetails;
			let originalDetails = el;
			// Check if the element's ID matches the hash value
			if (hash && originalDetails.id == hash.substring(1)) {
				// Unbind the 'arrive' event for the hash value
				document.unbindArrive(hash);

				// Find the closest 'details' element that is not already open
				let details = el.closest('details');
				while (details) {
					currentDetails = details;

					// Set the 'open' attribute to open the 'details' element
					if (details) {
						details.setAttribute('open', '');

						// Find the next closest 'details' element that is not already open
						details = details.parentElement.closest('details');

						if (details) {
							currentDetails = details;
						} else {
							details = null;
						}
					}
				}

				// If a 'details' element is found, perform scrolling and styling
				if (currentDetails) {
					/**
					 * Wait for the page to load before scrolling to the element.
					 * We no longer need a timeout method because we are using the 'arrive' event.
					 */
					currentDetails.setAttribute('open', '');
					currentDetails.classList.add('open');
					originalDetails.scrollIntoView({ behavior: 'smooth', block: 'center' });
				}
			}
		});
	};

	// Attach the 'arrive' event listener to the '#slot-header' element.
	document.arrive(
		'#slot-header',
		{ fireOnAttributesModification: true, existing: true },
		handleArriveEvent
	);
};

if (typeof SCSRenderAPI !== 'undefined') {
	if (bDebug) console.log('scsrenderapi is ready');
	startRenderApiDependents();
} else {
	document.addEventListener('scsrenderstart', () => {
		if (bDebug) console.log('scsrenderapi event fired');
		startRenderApiDependents();
	});

	document.addEventListener('scsrenderend', () => handleURLHash());
}
