import * as Sentry from '@sentry/browser';

//prevent sentry from initializing on robots
if (
	!['Mozilla/5.0 (compatible; Yeti', 'WebPageTest.org'].some((i) =>
		i.includes(navigator.userAgent)
	)
) {
	// Prevent Sentry from initializing on localhost or builder
	if (!["localhost", "sites/web", "sites/builder", "sites/preview"].some(i => window.location.href.includes(i))) {
		window.sentry = Sentry;
		Sentry.init({
			dsn: 'https://aad34e79bcf14e2c805630653584e955@o41069.ingest.sentry.io/4504617263824896',

			/*
			 * Global Toggle for Sentry
			 */
			enabled: true,

			/*
			 * The environment of the application you are monitoring with Sentry.
			 */
			environment: window.location.host.replace('.oraclecloud.com', ''),

			/*
			 * Sample rate to determine trace sampling.
			 *
			 * 0.0 = 0% chance of a given trace being sent(send no traces)
			 * 1.0 = 100 % chance of a given trace being sent (send all traces)
			 */
			tracesSampleRate: 0.01,

			/*
			 * A global sample rate to apply to all events
			 * 0.0 = 0% chance of a given event being sent (send no events)
			 * 1.0 = 100% chance of a given event being sent (send all events)
			 */
			sampleRate: 0.5,

			/*
			 * Controls if potentially sensitive data should be sent to Sentry by default.
			 */
			sendDefaultPii: true,

			integrations: [
				new Sentry.BrowserTracing({
					idleTimeout: 15000,
				}),
				new Sentry.Replay({
					maskAllText: false,
					blockAllMedia: false,
					maskAllInputs: false,
					errorSampleRate: 0.5,
					sessionSampleRate: 0,
				}),
			],

			tracePropagationTargets: ['localhost', /.*oraclecloud.com\/.*/, /.*mnhousing.org\/.*/],
		});
	}
}