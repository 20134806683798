/**
 * Looks for an element with the id layout-breadcrumbs,
 * then uses SCS to find the currentpage, rootpage, and the in between pages to display breadcrumbs withing the element
 */
const renderBreadcrumbs = () => {
	class pageInfo {
		constructor(inputId) {
			this.strPageName = SCS.structureMap[inputId].name
			this.intPageId = inputId
		}
	}

	/**
	 * Returns an array of page objects that are between the root and current pages
	 * @param {Number} intCurrentId id of the current page
	 * @param {Number} intRootId id of the top parent page
	 * @returns An array of page objects
	 */
	const getBetweenPages = (intCurrentId, intRootId) => {
		let arBetweenPages = []
		let intNextPageId = intCurrentId
		while (intNextPageId !== intRootId) {
			// iterates back through pages in the structur map until it hits the root, excluding the current page
			if (intNextPageId !== intCurrentId) {
				arBetweenPages.push(new pageInfo(intNextPageId))
			}
			intNextPageId = SCS.structureMap[intNextPageId].parentId
		}
		return arBetweenPages
	}

	/**
	 * constructs a crumb(list item) based on a passed in page object, optional: make the crumb a link
	 * @param {object} objPage page object the crumb is based on
	 * @param {boolean} bMakeLink boolean option to make crumb a link
	 */
	const getCrumb = (objPage, bMakeLink = true) => {
		let elCrumbListItem = document.createElement('li')
		// if the crumb isn't the current page, bMakeLink should be set to true so it will be a clickable link
		if (bMakeLink) {
			let elCrumbLink = document.createElement('a')
			elCrumbLink.href = SCSRenderAPI.getPageLinkData(objPage.intPageId).href
			elCrumbLink.innerText = objPage.strPageName
			elCrumbListItem.appendChild(elCrumbLink)
		} else {
			// the current page should have the bMakeLink set to false so that it isnt clickable
			elCrumbListItem.innerText = objPage.strPageName
			elCrumbListItem.ariaCurrent = 'page'
		}
		elBreadcrumbList.prepend(elCrumbListItem)
	}

	// get the page object for the root page and the current page so that they can be appended to the crumblist
	let objCurrentPageInfo = new pageInfo(SCS.navigationCurr)
	let objRootPageInfo = new pageInfo(SCS.navigationRoot)
	// target the breadcrumb container to prepend/append new elements to
	let elBreadcrumbContainer = document.querySelector('#layout-breadcrumbs')
	let elBreadcrumbList = document.createElement('ol')

	if (objCurrentPageInfo.intPageId !== objRootPageInfo.intPageId) {
		// create a list of page objects that are between the root and current page
		let arBetweenPages = getBetweenPages(
			objCurrentPageInfo.intPageId,
			objRootPageInfo.intPageId
		)
		getCrumb(objCurrentPageInfo, false)
		for (let objPage of arBetweenPages) {
			getCrumb(objPage)
		}
		elBreadcrumbContainer.prepend(elBreadcrumbList)
		// if the root and current pages are the same id the root should not be appended to the breadcrumbs

		getCrumb(objRootPageInfo)
	}
}

export default renderBreadcrumbs
